import React, { useState } from "react";

import './../styles/main.scss'

import tan24 from './../img/Tan24.jpg'
import cream from './../img/icons/cream.png'
import spray from './../img/icons/spray.png'
import clock from './../img/icons/clock.png'
import shower from './../img/icons/shower.png'
import stars from './../img/icons/stars.png'


import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const Main = () => {
    const navigate = useNavigate()
    const [loaded, setLoaded] = useState(false)

    const handleNavigate = (e) => {
        window.scrollTo(0, 0)
        navigate(`${e.target.id}`)
    }

    const load = () => {
        setLoaded(true)
    }

    return (
        <div>
            {!loaded ?
                <Loading />
                :
                <></>
            }
            <div className={`MainContentContainer ${loaded ? 'Loaded' : ''}`} onLoad={load}>
                <div className="Slider">
                    <div className="SliderRow">
                        <div className="MainLogoContainer">
                            <div className="Logo">Pudra</div>
                            <div className="LogoSub">СТУДИЯ&nbsp; МОМЕНТАЛЬНОГО&nbsp; ЗАГАРА</div>
                        </div>
                        <div className="MainPhoto"></div>
                    </div>
                </div>
                <div className="MainTextContainer">
                    <div className="MainTextBorder">
                        <div className="MTImg"><img src={tan24} alt="tan" /></div>
                        <div className="MTText">
                            <div className="MTTSub">Добро пожаловать в мир моментального загара!</div>
                            <div className="MTTPar">Мы специализируемся на создании идеального загара, 
                            который подарит вам безупречный вид и ощущение лета круглый год.</div>
                            <div className="MTTAboutUs">
                                <div className="MTTBtn" id="/aboutus" onClick={handleNavigate}>О&nbsp;&nbsp;нас</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TanDescriptionContainer">
                    <div className="TanDescription" id="scrollToMore">
                        <div className="TanAbout">
                            <div className="TanDSub">Этапы моментального загара</div>
                            <div className="TanSteps">
                                <div className="OneStep">
                                    <div className="StepImg"><img src={cream} alt="cream" /></div>
                                    <div className="StepCol">
                                        <div className="StepSub">
                                            <div className="StepSubText">Подготовка кожи</div>
                                            <div className="StepSubNum">01</div>
                                        </div>
                                        <div className="StepLine"></div>
                                        <div className="StepDesc">Увлажняйте и скабируйте кожу за два дня до процедуры</div>
                                    </div>
                                </div>
                                <div className="OneStep">
                                    <div className="StepImg"><img src={spray} alt="spray" /></div>
                                    <div className="StepCol">
                                        <div className="StepSub">
                                            <div className="StepSubText">Процедура</div>
                                            <div className="StepSubNum">02</div>
                                        </div>
                                        <div className="StepLine"></div>
                                        <div className="StepDesc">Проконсультируйтесь со специалистом. Он подберет лосьон под ваш тон кожи и проведёт процедуру</div>
                                    </div>
                                </div>
                                <div className="OneStep">
                                    <div className="StepImg"><img src={clock} alt="clock" /></div>
                                    <div className="StepCol">
                                        <div className="StepSub">
                                            <div className="StepSubText">Выдержка лосьона</div>
                                            <div className="StepSubNum">03</div>
                                        </div>
                                        <div className="StepLine"></div>
                                        <div className="StepDesc">
                                            В течение 3-8 часов носите легкую не облегающую одежду. 
                                            Точное время зависит от типа процедуры и ваших предпочтений
                                        </div>
                                    </div>
                                </div>
                                <div className="OneStep">
                                    <div className="StepImg"><img src={shower} alt="shower" /></div>
                                    <div className="StepCol">
                                        <div className="StepSub">
                                            <div className="StepSubText">Принятие душа</div>
                                            <div className="StepSubNum">04</div>
                                        </div>
                                        <div className="StepLine"></div>
                                        <div className="StepDesc">Смойте лосьон с кожи теплой водой. Не используйте гели и другие средства для душа</div>
                                    </div>
                                </div>
                                <div className="OneStep">
                                    <div className="StepImg"><img src={stars} alt="stars" /></div>
                                    <div className="StepCol">
                                        <div className="StepSub">
                                            <div className="StepSubText">Уход за загаром</div>
                                            <div className="StepSubNum">05</div>
                                        </div>
                                        <div className="StepLine"></div>
                                        <div className="StepDesc">Используйте увлажняющий крем для поддержания результата. Он сохраняется до 14 дней</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ServicesBtn" id="/services" onClick={handleNavigate}>ОЗНАКОМИТЬСЯ С УСЛУГАМИ</div>
                </div>
            </div>
        </div>
    );
}
 
export default Main;