import React, { useState } from "react";

import './../styles/tips.scss'

const Tips = () => {
    const [loaded, setLoaded] = useState(true)

    const load = () => {
        setLoaded(true)
    }

    return (
        <div>
            <div className={`TipsContainer ${loaded ? 'Loaded' : ''}`} onLoad={load}>
                <div className="Tip TipBeige">
                    <div className="TipSub">Как подготовиться к моментальному загару?</div>
                    <div className="TipContent">
                        <div className="TipPar">
                            Загар — это мечта многих людей, особенно перед отпуском или важным мероприятием. 
                            Однако, не всегда есть возможность провести длительное время на пляже или в солярии, 
                            чтобы получить желаемый загар. В этой статье мы расскажем о том, как подготовиться 
                            к моментальному загару и достичь желаемого результата.
                        </div>
                        <div className="TipPar">
                            Перед нанесением средства для моментального загара необходимо подготовить кожу. 
                            За 2-3 дня до процедуры нужно провести пилинг, чтобы удалить ороговевшие клетки и сделать 
                            кожу более гладкой. После пилинга необходимо нанести увлажняющий крем, чтобы кожа была мягкой 
                            и готовой к нанесению средства для загара.
                        </div>
                        <div className="TipPar">
                            Непосредственно перед процедурой моментального загара необходимо тщательно вымыть руки и нанести 
                            защитный крем на локти, колени и лодыжки, чтобы избежать пятен и переходов.
                        </div>
                        <div className="TipPar">
                            После нанесения средства для моментального загара необходимо избегать контакта с водой и избегать 
                            сильного потоотделения в течение нескольких часов. Через 6-8 часов можно принять душ, 
                            но не использовать грубые скрабы или мыло. Чтобы сохранить загар, необходимо регулярно увлажнять 
                            кожу и избегать длительного пребывания на солнце.
                        </div>
                        <div className="TipPar">
                            Подготовка к моментальному загару не так сложна, если следовать этим простым правилам. 
                            Помните, что здоровая кожа всегда красива, поэтому не забывайте о ее уходе и защите в любое время года.
                        </div>
                    </div>
                </div>
                <div className="TipLine"></div>
                <div className="Tip TipBlue">
                    <div className="TipSub">Кому можно делать моментальный загар?</div>
                    <div className="TipContent">
                        <div className="TipPar">
                            Моментальный загар является безопасным и эффективным способом 
                            получения красивого загара без необходимости проводить много времени на солнце. 
                        </div>
                        <div className="TipPar">
                            Этот метод подходит для людей всех возрастов, включая детей и беременных женщин. 
                            В отличие от солнечных лучей, моментальный загар не содержит вредных ультрафиолетовых лучей, 
                            которые могут повредить кожу. Более того, моментальный загар не влияет на гормональный баланс в организме, 
                            что делает его безопасным.
                        </div>
                        <div className="TipPar">
                            Однако, перед использованием моментального загара, 
                            необходимо проконсультироваться с врачом, 
                            особенно если у вас есть аллергии или другие проблемы со здоровьем.
                        </div>
                    </div>
                </div>
                <div className="TipLine"></div>
                <div className="Tip TipBeige">
                    <div className="TipSub">Как правильно загорать в солярии?</div>
                    <div className="TipContent">
                        <div className="TipPar">
                            Моментальный загар является безопасным и эффективным способом 
                            получения красивого загара без необходимости проводить много времени на солнце. 
                        </div>
                        <div className="TipPar">
                            Этот метод подходит для людей всех возрастов, включая детей и беременных женщин. 
                            В отличие от солнечных лучей, моментальный загар не содержит вредных ультрафиолетовых лучей, 
                            которые могут повредить кожу. Более того, моментальный загар не влияет на гормональный баланс в организме, 
                            что делает его безопасным.
                        </div>
                        <div className="TipPar">
                            Однако, перед использованием моментального загара, 
                            необходимо проконсультироваться с врачом, 
                            особенно если у вас есть аллергии или другие проблемы со здоровьем.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Tips;