import React, { useContext, useState } from "react";
import './../styles/bookings.scss'
import Calendar from "./Calendar";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { fetchBookingsOneDay, deleteBooking, createBooking, sendMessageClient, sendMessageAdmin } from './../http/bookingAPI'

const Bookings = observer(() => {
    const [chooseDate, setChooseDate] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteTime, setDeleteTime] = useState('')
    const [deleteName, setDeleteName] = useState('')
    const [deletePhone, setDeletePhone] = useState('')
    const [createTime, setCreateTime] = useState('')
    const [createType, setCreateType] = useState('')
    const [createPhone, setCreatePhone] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [timeValue, setTimeValue] = useState('12:00')
    const [typeValue, setTypeValue] = useState('Тип услуги')
    const [nameValue, setNameValue] = useState('')
    const {booking} = useContext(Context)

    const bookingNext = async () => {
        if (booking.selectedDate !== null) {
            const bookingsCalendar = document.querySelector('.BookingsCalendar')
            bookingsCalendar.classList.add('None')
            try {
                await fetchBookingsOneDay(booking.selectedDate).then(data => booking.setBooking(data))
                booking.setIsEmpty(false)
            } catch (e) {
                booking.setIsEmpty(true)
            }
            const DBBookings = document.querySelector('.DBBookings')
            DBBookings.classList.remove('None')
        } else {
            const noDate = document.querySelector('.NoDate')
            noDate.classList.remove('None')
            console.log(window.selectedDate)
        }
    }

    const DBSelectedDate = (date) => {
        try {
            const [year, month, day] = date.split('-')
            const newDate = `${day}.${month}.${year}`
            return newDate
        } catch (e) {

        }
    }

    const DBTime = (time) => {
        try {
            const [hour, min] = time.split(':')
            const newTime = `${hour}:${min}`
            return newTime
        } catch (e) {

        }
    }

    const DBPhone = (phone) => {
        const cleaned = ('' + phone).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/)
        const formattedNumber = !match ? '' : `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
        return formattedNumber
    }

    const DBBookingType = (type) => {
        switch (type) {
            case 'MOMENT':
                return 'Моментальный загар'
            case 'EXPRESS':
                return 'Экспресс загар'
            case 'PREMIUM':
                return 'Премиум загар'
            default:
                break;
        }
    }

    const openModalCreate = () => {
        document.body.classList.add('Lock')
        const modal = document.querySelector('.DBCreateBookingModal')
        modal.classList.remove('None')
        setTypeValue('Тип услуги')
        const typeColor = document.querySelector('.DBCreateType')
        typeColor.setAttribute('style', '')
        const timeColor = document.querySelector('.DBCreateInputTime')
        timeColor.setAttribute('style', '')
        setNameValue('')
        setPhoneNumber('')
        setCreatePhone('')
        setCreateType('')
        setCreateTime('')
        setTimeValue('12:00')
        const alerts = document.getElementsByClassName('InputAlert')
        for (let i of alerts) {
            i.classList.add('None')
        }
        const inputTime = document.querySelector('.DBCreateInputTime')
        const inputName = document.querySelector('.DBCreateInputName')
        const inputPhone = document.querySelector('.DBCreatePhoneContainer')
        const inputType = document.querySelector('.DBCreateType')
        inputTime.classList.remove('RedBorder')
        inputName.classList.remove('RedBorder')
        inputPhone.classList.remove('RedBorder')
        inputType.classList.remove('RedBorder')
    }

    const closeModalCreate = () => {
        document.body.classList.remove('Lock')
        const modal = document.querySelector('.DBCreateBookingModal')
        modal.classList.add('None')
        console.log('закрыто')
    }

    const openModalDelete = () => {
        document.body.classList.add('Lock')
        const modal = document.querySelector('.DBDeleteBookingModal')
        modal.classList.remove('None')
    }

    const closeModalDelete = () => {
        document.body.classList.remove('Lock')
        const modal = document.querySelector('.DBDeleteBookingModal')
        modal.classList.add('None')
    }

    const deleteBookingDB = async (id) => {
        await deleteBooking(id)
        closeModalDelete()
        try {
            await fetchBookingsOneDay(booking.selectedDate).then(data => booking.setBooking(data))
        } catch (e) {
            booking.deleteLast()
            booking.setIsEmpty(true)
        }
    }

    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, '')
        setCreatePhone('7'+ cleaned)
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/)
        let formattedNumber
        switch (cleaned.length) {
            case 10:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
                break
            case 9:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
                break
            case 8:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}-`
                break
            case 7:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}`
                break
            case 6:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-`
                break
            case 5:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}`
                break
            case 4:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}`
                break
            case 3:
                formattedNumber = !match ? '' : `(${match[1]}) `
                break
            case 2:
                formattedNumber = !match ? '' : `(${match[1]}`
                break
            case 1:
                formattedNumber = !match ? '' : `(${match[1]}`
                break
            case 0:
                formattedNumber = !match ? '' : ``
                break

            default:
                break
        }
    
        return formattedNumber;
    }
    
    const handlePhoneChange = (e) => {
        const input = e.target.value
        const formattedNumber = formatPhoneNumber(input)
        setPhoneNumber(formattedNumber)
    }

    const handleBackspace = (e) => {
        if (e.keyCode === 8 || e.key === 'Backspace') {
            e.preventDefault()
            const cleaned = ('' + e.target.value).replace(/\D/g, '')
            const match = cleaned.split('')
            let formattedNumber
            switch (cleaned.length) {
                case 10:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-${match[6]}${match[7]}-${match[8]}`
                    break
                case 9:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-${match[6]}${match[7]}-`
                    break
                case 8:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-${match[6]}`
                    break
                case 7:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-`
                    break
                case 6:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}`
                    break
                case 5:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}`
                    break
                case 4:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) `
                    break
                case 3:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}`
                    break
                case 2:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}`
                    break
                case 1:
                    formattedNumber = !match ? '' : ``
                    break
                case 0:
                    formattedNumber = !match ? '' : ``
                    break

                default:
                    break
            }
            setPhoneNumber(formattedNumber)
        }
    }

    const typeMenu = (e) => {
        const menu = document.querySelector('.DBCreateTypeList')
        menu.classList.toggle('None')
    }

    const createBookingDB = async () => {
        if (booking.selectedDate && createTime.length === 8 && nameValue.length > 0 && createPhone.length === 11 && createType.length > 0) {
            try {
                await createBooking(booking.selectedDate, createTime, nameValue, createPhone, createType)
                await sendMessageAdmin(booking.selectedDate, createTime, nameValue, createPhone, createType)
                await sendMessageClient(booking.selectedDate, createTime, nameValue, createPhone, createType)
                closeModalCreate()
                await fetchBookingsOneDay(booking.selectedDate).then(data => booking.setBooking(data))
            } catch (e) {
                const alert = document.querySelector('.TimeIsBooked')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreateInputTime')
                input.classList.add('RedBorder')
            }
        } else {
            if (createTime.length !== 8) {
                const alert = document.querySelector('.TimeAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreateInputTime')
                input.classList.add('RedBorder')
            }
            if (nameValue.length === 0) {
                const alert = document.querySelector('.NameAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreateInputName')
                input.classList.add('RedBorder')
            }
            if (createPhone.length !== 11) {
                const alert = document.querySelector('.PhoneAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreatePhoneContainer')
                input.classList.add('RedBorder')
            }
            if (createType.length === 0) {
                const alert = document.querySelector('.TypeAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreateType')
                input.classList.add('RedBorder')
            }
        }
    }

    return (
        <div className="BookingsContainer">
            <div className="BookingsSub">Записи</div>
            {!chooseDate ?
                <div className="BookingsDate" onClick={() => setChooseDate(true)}>Выбрать дату</div>
                :
                <div className="BookingsCalendar">
                    <Calendar />
                    <div className="NoDate None">Дата не выбрана!</div>
                    <div className="OpenBookings" onClick={bookingNext}>Далее</div>
                </div>
            }
            <div className="DBBookings None">
                {!booking.isEmpty ?
                    <div className="DBBookingsContainer">
                        <div className="DBSelectedDate">{DBSelectedDate(booking.selectedDate)}</div>
                        <div className="DBBookingTable">
                            <div className="DBBookingCol">
                                {booking.booking.map(booking => 
                                    <div key={booking.id} className="DBBookingItem">
                                        <div className="DBBookingTime">{DBTime(booking.time)}</div>
                                    </div>
                                )}
                            </div>
                            <div className="DBBookingCol">
                                {booking.booking.map(booking => 
                                    <div key={booking.id} className="DBBookingItem">
                                        <div className="DBBookingName">{booking.name}</div>
                                    </div>
                                )}
                            </div>
                            <div className="DBBookingCol">
                                {booking.booking.map(booking => 
                                    <div key={booking.id} className="DBBookingItem">
                                        <a className="DBBookingPhone" href={`tel: +${booking.phone}`}>{DBPhone(booking.phone)}</a>
                                    </div>
                                )}
                            </div>
                            <div className="DBBookingCol">
                                {booking.booking.map(booking => 
                                    <div key={booking.id} className="DBBookingItem">
                                        <div className="DBBookingType">{DBBookingType(booking.type)}</div>
                                    </div>
                                )}
                            </div>
                            <div className="DBBookingCol">
                                {booking.booking.map(booking => 
                                    <div key={booking.id} className="DBBookingItem">
                                        <div 
                                            className="DBBookingDelete" 
                                            onClick={() => {
                                                openModalDelete()
                                                setDeleteId(booking.id)
                                                setDeleteName(booking.name)
                                                setDeletePhone(booking.phone)
                                                setDeleteTime(booking.time)
                                            }}
                                        >
                                            Удалить
                                        </div>
                                    </div>
                                )}
                                <div className="DBDeleteBookingModal None">
                                    <div className="DBDeleteModalPanel">
                                        <div className="DBDeleteAlert">Удалить запись?</div>
                                        <div className="DeleteDate">{DBSelectedDate(booking.selectedDate)}</div>
                                        <div className="DeleteTime">{DBTime(deleteTime)}</div>
                                        <div className="DeleteName">{deleteName}</div>
                                        <div className="DeletePhone">{DBPhone(deletePhone)}</div>
                                        <div className="DBDeleteButtons">
                                            <div className="DBDeleteNo" onClick={closeModalDelete}>Отмена</div>
                                            <div 
                                                className="DBDeleteYes" 
                                                onClick={() => {
                                                    deleteBookingDB(deleteId)
                                                }}
                                            >
                                                Удалить
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="NoBookings">
                        <div className="DBSelectedDate">{DBSelectedDate(booking.selectedDate)}</div>
                        <div className="NoBookingsSub">Записей нет</div>
                    </div>
                }
                {booking.futureDate ?
                    <div className="AddNewBooking" onClick={openModalCreate}>Создать новую запись</div>
                    :
                    <></>
                }
                <div className="DBCreateBookingModal None">
                    <div className="DBCreateModalPanel">
                        <div className="DBCreateAlert">Создать запись</div>
                        <div className="DBCreateDate">{DBSelectedDate(booking.selectedDate)}</div>
                        <div className="CreateInputs">
                            <input 
                                className="DBCreateInputTime" 
                                type="time" 
                                value={timeValue}
                                onChange={(e) => {
                                    setCreateTime(`${e.target.value}:00`)
                                    setTimeValue(`${e.target.value}`)
                                    e.target.setAttribute('style', 'color: black')
                                    e.target.classList.remove('RedBorder')
                                    const alert = document.querySelector('.TimeAlert')
                                    alert.classList.add('None')
                                    const DBAlert = document.querySelector('.TimeIsBooked')
                                    DBAlert.classList.add('None')
                                }} 
                            />
                            <div className="InputAlert TimeAlert None">Выберите время</div>
                            <div className="InputAlert TimeIsBooked None">Это время уже занято</div>
                            <input 
                                className="DBCreateInputName" 
                                type="text" 
                                placeholder="Имя" 
                                value={nameValue}
                                onChange={(e) => {
                                    setNameValue(`${e.target.value}`)
                                    e.target.classList.remove('RedBorder')
                                    const alert = document.querySelector('.NameAlert')
                                    alert.classList.add('None')
                                }}
                            />
                            <div className="InputAlert NameAlert None">Введите имя</div>
                            <div className="DBCreatePhoneContainer">
                                <div className="DBCreatePhonePre">+7</div>
                                <input 
                                    className="DBCreateInputPhone"
                                    type="text" 
                                    maxLength="15" 
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        handlePhoneChange(e)
                                        const input = document.querySelector('.DBCreatePhoneContainer')
                                        input.classList.remove('RedBorder')
                                        const alert = document.querySelector('.PhoneAlert')
                                    alert.classList.add('None')
                                    }}
                                    onKeyDown={handleBackspace}
                                    placeholder="(999) 999-99-99"
                                />
                            </div>
                            <div className="InputAlert PhoneAlert None">Некорректный номер телефона</div>
                            <div className="DBCreateType" onClick={typeMenu}>
                                {typeValue} <span>▴</span>
                                <div className="DBCreateTypeList None">
                                    <div 
                                        className="DBCreateTypeItem" 
                                        id="MOMENT" 
                                        onClick={(e) => {
                                            setTypeValue(`${e.target.innerText}`)
                                            setCreateType(`${e.target.id}`)
                                            const color = document.querySelector('.DBCreateType')
                                            color.setAttribute('style', 'color: black')
                                            const input = document.querySelector('.DBCreateType')
                                            input.classList.remove('RedBorder')
                                            const alert = document.querySelector('.TypeAlert')
                                            alert.classList.add('None')
                                        }}
                                    >
                                        Моментальный загар
                                    </div>
                                    <div 
                                        className="DBCreateTypeItem" 
                                        id="EXPRESS"
                                        onClick={(e) => {
                                            setTypeValue(`${e.target.innerText}`)
                                            setCreateType(`${e.target.id}`)
                                            const color = document.querySelector('.DBCreateType')
                                            color.setAttribute('style', 'color: black')
                                            const input = document.querySelector('.DBCreateType')
                                            input.classList.remove('RedBorder')
                                        }}
                                    >
                                        Экспресс загар
                                    </div>
                                    <div 
                                        className="DBCreateTypeItem" 
                                        id="PREMIUM"
                                        onClick={(e) => {
                                            setTypeValue(`${e.target.innerText}`)
                                            setCreateType(`${e.target.id}`)
                                            const color = document.querySelector('.DBCreateType')
                                            color.setAttribute('style', 'color: black')
                                            const input = document.querySelector('.DBCreateType')
                                            input.classList.remove('RedBorder')
                                        }}
                                    >
                                        Премиум загар
                                    </div>
                                </div>
                            </div>
                            <div className="InputAlert TypeAlert None">Выберите тип услуги</div>
                        </div>
                        <div className="BookingIsExist None">Это время уже занято!</div>
                        <div className="DBCreateButtons">
                            <div className="DBCreateNo" onClick={closeModalCreate}>Отмена</div>
                            <div 
                                className="DBCreateYes" 
                                onClick={() => {
                                    createBookingDB()
                                }}
                            >
                                Создать
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})
 
export default Bookings;