import { $host } from ".";

export const createWindow = async (date, time) => {
    const {data} = await $host.post('api/window', {date, time})
    return data
}

export const fetchWindows = async () => {
    const {data} = await $host.get('api/window')
    return data
}

export const fetchWindowsOneDay = async (date) => {
    const {data} = await $host.get('api/window/day', {params: {date}})
    return data
}

export const deleteWindow = async (id) => {
    await $host.delete('api/window', {params: {id}})
}