import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import './../styles/clients.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { fetchClients, searchClients, searchBookings } from "../http/clientAPI";
import { deleteBooking } from "../http/bookingAPI";
import { Context } from "..";


const Clients = observer(() => {
    const [hasMore, setHasMore] = useState(true)
    const [search, setSearch] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [isBooking, setIsBooking] = useState(false)
    const [clientName, setClientName] = useState('')
    const [clientPhone, setClientPhone] = useState('')
    const [hasBookings, setHasBookings] = useState(false)
    const [userBooking, setUserBooking] = useState([])
    const {client} = useContext(Context)

    useEffect(() => {
        client.setOffset(1)
        client.clearClient()
        loadClients()
        // eslint-disable-next-line
    }, [])

    const loadClients = async () => {
        try {
            await fetchClients(client.offset, client.limit).then(({clients, pages}) => {
                client.setClient(clients)
                client.setPages(pages)
                console.log(client.client)
            })
            client.setOffset(client.offset + 1)
        } catch (e) {
            setHasMore(false)
        }
    }

    const findClients = async (e) => {
        client.setSearchClient([])
        try {
            await searchClients(e).then((data) => {client.setSearchClient(data)})
            console.log(client.searchClient)
        } catch (e) {

        }
    }

    const formatPhone = (phone) => {
        const cleaned = ('' + phone).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/)
        const formattedNumber = !match ? '' : `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
        return formattedNumber
    }

    const stopSearch = (e) => {
        if ((e.keyCode === 8 || e.key === 'Backspace') && search.length === 1 && isSearch) {
            setIsSearch(false)
        }
    }

    const findBookings = async (user) => {
        try {
            await searchBookings(user.id).then(data => client.setClientBookings(data))
            setUserBooking(user)
            setIsBooking(true)
            const mainSub = document.querySelector('.ClientsSub')
            mainSub.classList.add('None')
            const searcher = document.querySelector('.SearchInput')
            searcher.classList.add('None')
            setClientName(user.name)
            setClientPhone(user.phone)
            const clientSub = document.querySelector('.ClientBookingsSub')
            clientSub.classList.remove('None')
            const phoneSub = document.querySelector('.PhoneSub')
            phoneSub.classList.remove('None')
            if (client.clientBookings.length > 0) {
                setHasBookings(true)
            } else {
                setHasBookings(false)
            }
        } catch (e) {
            
        }
    }

    const formatDate = (date) => {
        const [year, month, day] = date.split('-')
        const newDate = `${day}.${month}.${year}`
        return newDate
    }

    const formatTime = (time) => {
        const [hour, min] = time.split(':')
        const newTime = `${hour}:${min}`
        return newTime
    }

    const formatType = (type) => {
        switch (type) {
            case 'MOMENT':
                return 'Моментальный загар'
            case 'EXPRESS':
                return 'Экспресс загар'
            case 'PREMIUM':
                return 'Премиум загар'
            default:
                break;
        }
    }

    const openModalDelete = () => {
        document.body.classList.add('Lock')
        const modal = document.querySelector('.DBDeleteBookingModal')
        modal.classList.remove('None')
    }

    const closeModalDelete = () => {
        document.body.classList.remove('Lock')
        const modal = document.querySelector('.DBDeleteBookingModal')
        modal.classList.add('None')
    }

    const deleteBookingDB = async (id) => {
        await deleteBooking(id)
        closeModalDelete()
        findBookings(userBooking)
    }

    return (
        <div className="ClientsContainer">
            <div className="ClientsSub">Список клиентов</div>
            <div className="ClientBookingsSub None">Записи клиента {clientName}</div>
            <a className="PhoneSub None" href={`tel: +${clientPhone}`}>{formatPhone(clientPhone)}</a>
            <input 
                className="SearchInput"
                type="text" 
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                    if (e.target.value.length > 0) {
                        setIsSearch(true)
                        findClients(e.target.value)
                    }
                }}
                onKeyDown={(e) => stopSearch(e)}
                placeholder="Поиск"
            />
            {!isBooking ?
                <div>
                    {!isSearch ?
                            <InfiniteScroll
                                dataLength={client.client.length}
                                next={loadClients}
                                hasMore={hasMore}
                            >
                                <div className="DBClientTable">
                                    <div className="DBClientCol">
                                        {client.client.map((client) => (
                                            <div key={client.id} className="DBClientItem">
                                                <div className="DBClientName">{client.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="DBClientCol">
                                        {client.client.map((client) => (
                                            <div key={client.id} className="DBClientItem">
                                                <a className="DBClientPhone" href={`tel: +${client.phone}`}>{formatPhone(client.phone)}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="DBClientCol">
                                        {client.client.map((client) => (
                                            <div key={client.id} className="DBClientItem">
                                                <div className="DBClientView" onClick={() => findBookings(client)}>Просмотр</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                
                            </InfiniteScroll>
                        :
                        <div>
                            {client.searchClient.length > 0 ?
                                <div className="DBClientTable">
                                    <div className="DBClientCol">
                                        {client.searchClient.map((client) => (
                                            <div key={client.id} className="DBClientItem">
                                                <div className="DBClientName">{client.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="DBClientCol">
                                        {client.searchClient.map((client) => (
                                            <div key={client.id} className="DBClientItem">
                                                <a className="DBClientPhone" href={`tel: +${client.phone}`}>{formatPhone(client.phone)}</a>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="DBClientCol">
                                        {client.searchClient.map((client) => (
                                            <div key={client.id} className="DBClientItem">
                                                <div className="DBClientView" onClick={() => findBookings(client)}>Просмотр</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                <div className="NotFound">Клиенты не найдены</div>
                            }
                        </div>
                    }
                </div>
                :
                <div>
                    {hasBookings ?
                        <div>
                            <div className="DBClientTable">
                                <div className="DBClientCol">
                                    {client.clientBookings.map((booking) => (
                                        <div key={booking.id} className="DBClientItem">
                                            <div className="DBClientDate">{formatDate(booking.date)}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="DBClientCol">
                                    {client.clientBookings.map((booking) => (
                                        <div key={booking.id} className="DBClientItem">
                                            <div className="DBClientTime">{formatTime(booking.time)}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="DBClientCol">
                                    {client.clientBookings.map((booking) => (
                                        <div key={booking.id} className="DBClientItem">
                                            <div className="DBClientType">{formatType(booking.type)}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="DBClientCol">
                                    {client.clientBookings.map((booking) => (
                                        <div key={booking.id} className="DBClientItem">
                                            <div className="DBClientDelete" onClick={openModalDelete}>Удалить</div>
                                            <div className="DBDeleteBookingModal None">
                                                <div className="DBDeleteModalPanel">
                                                    <div className="DBDeleteAlert">Удалить запись?</div>
                                                    <div className="DeleteDate">{formatDate(booking.date)}</div>
                                                    <div className="DeleteTime">{formatTime(booking.time)}</div>
                                                    <div className="DeleteName">{booking.name}</div>
                                                    <div className="DeletePhone">{formatPhone(booking.phone)}</div>
                                                    <div className="DBDeleteButtons">
                                                        <div className="DBDeleteNo" onClick={closeModalDelete}>Отмена</div>
                                                        <div 
                                                            className="DBDeleteYes" 
                                                            onClick={() => {
                                                                deleteBookingDB(booking.id)
                                                            }}
                                                        >
                                                            Удалить
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="HasNoBookings">У данного клиента нет записей</div>
                    }
                </div>
            }
        </div>
    );
})
 
export default Clients;