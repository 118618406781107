import React, { useEffect } from "react";
import './../styles/loading.scss'

import blob1 from './../img/blobs/blob1.svg'
import blob2 from './../img/blobs/blob2.svg'
import blob3 from './../img/blobs/blob3.svg'
import blob4 from './../img/blobs/blob4.svg'
import blob5 from './../img/blobs/blob5.svg'
import blob6 from './../img/blobs/blob6.svg'
import blob7 from './../img/blobs/blob7.svg'
import blob8 from './../img/blobs/blob8.svg'
import blob9 from './../img/blobs/blob9.svg'
import blob10 from './../img/blobs/blob10.svg'
import blob11 from './../img/blobs/blob11.svg'
import blob12 from './../img/blobs/blob12.svg'

const Loading = () => {

    useEffect(() => {
        const blobs = document.querySelectorAll('.Blob')
        let i = 0
        setInterval(() => {
            blobs[0].classList.remove('None')
            if (i === 11) i = 0
            for (let j = 0; j < blobs.length; j++) {
                j !== i ? blobs[j].classList.add('None') : blobs[j].classList.remove('None')
            }
            i++
        }, 200)
    })

    return (
        <div className="LoadingContainer">
            <div className="Loader">
                <img className="Blob" src={blob1} alt="blob" />
                <img className="Blob None" src={blob2} alt="blob" />
                <img className="Blob None" src={blob3} alt="blob" />
                <img className="Blob None" src={blob4} alt="blob" />
                <img className="Blob None" src={blob5} alt="blob" />
                <img className="Blob None" src={blob6} alt="blob" />
                <img className="Blob None" src={blob7} alt="blob" />
                <img className="Blob None" src={blob8} alt="blob" />
                <img className="Blob None" src={blob9} alt="blob" />
                <img className="Blob None" src={blob10} alt="blob" />
                <img className="Blob None" src={blob11} alt="blob" />
                <img className="Blob None" src={blob12} alt="blob" />
            </div>
        </div>
    );
}
 
export default Loading;