import {makeAutoObservable} from 'mobx'

export default class ClientStore {
    constructor() {
        this._client = []
        this._searchClient = []
        this._offset = 1
        this._limit = 20
        this._pages = 0
        this._clientBookings = []
        makeAutoObservable(this)
    }

    setClient(client) {
        let clients = this._client
        for (let i of client) {
            clients.push(i)
        }
        this._client = clients
    }

    clearClient() {
        this._client = []
    }

    setSearchClient(client) {
        this._searchClient = client
    }

    resetClient(client) {
        this._client = client
    }

    setOffset(offset) {
        this._offset = offset
    }

    setLimit(limit) {
        this._limit = limit
    }

    setPages(pages) {
        this._pages = pages
    }

    setClientBookings(bookings) {
        this._clientBookings = bookings
    }

    get client() {
        return this._client
    }

    get searchClient() {
        return this._searchClient
    }

    get offset() {
        return this._offset
    }

    get limit() {
        return this._limit
    }

    get pages() {
        return this._pages
    }

    get clientBookings() {
        return this._clientBookings
    }
}