import React, { useRef, useEffect, useContext } from "react";
import './../styles/calendar.scss'
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { Context } from "..";
import { observer } from "mobx-react-lite";

const Calendar = observer(() => {
    const {window, booking, clientBook} = useContext(Context)
    const datepickerRef = useRef(null);

    useEffect(() => {
        window.setSelectedDate(null)
        booking.setSelectedDate(null)
        clientBook.setSelectedDate(null)
        const datepicker = new AirDatepicker(datepickerRef.current, {
            onSelect: (formattedDate) => {
                if (formattedDate.formattedDate) {
                    const [day, month, year] = formattedDate.formattedDate.split('.')
                    const dateDB = `${year}-${month}-${day}`
                    window.setSelectedDate(dateDB)
                    booking.setSelectedDate(dateDB)
                    clientBook.setSelectedDate(dateDB)
                } else {
                    window.setSelectedDate(null)
                    booking.setSelectedDate(null)
                    clientBook.setSelectedDate(null)
                }
            }
        });

        return () => {
            datepicker.destroy();
        };
    }, [window, booking, clientBook]);
  
    return (
        <div ref={datepickerRef}></div>
    );
})
 
export default Calendar;