import React, { useContext, useState } from "react";
import './../styles/windows.scss'
import Calendar from "./Calendar";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { fetchWindowsOneDay, deleteWindow, createWindow } from "../http/windowAPI";

const Windows = observer(() => {
    const [chooseDate, setChooseDate] = useState(false)
    const [deleteTime, setDeleteTime] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [createTime, setCreateTime] = useState('')
    const {window} = useContext(Context)

    const windowNext = async () => {
        if (window.selectedDate !== null) {
            const windowsCalendar = document.querySelector('.WindowsCalendar')
            windowsCalendar.classList.add('None')
            console.log(window.selectedDate)
            try {
                await fetchWindowsOneDay(window.selectedDate).then(data => window.setWindow(data))                
                window.setIsEmpty(false)
            } catch (e) {
                window.setIsEmpty(true)
            }
            const DBWindows = document.querySelector('.DBWindows')
            DBWindows.classList.remove('None')
        } else {
            const noDate = document.querySelector('.NoDate')
            noDate.classList.remove('None')
            console.log(window.selectedDate)
        }
    }

    const DBSelectedDate = (date) => {
        try {
            const [year, month, day] = date.split('-')
            const newDate = `${day}.${month}.${year}`
            return newDate
        } catch (e) {

        }
    }

    const DBTime = (time) => {
        const [hour, min] = time.split(':')
        const newTime = `${hour}:${min}`
        return newTime
    }

    const openModalDelete = () => {
        document.body.classList.add('Lock')
        const modal = document.querySelector('.DBDeleteWindowModal')
        modal.classList.remove('None')
    }

    const closeModalDelete = () => {
        document.body.classList.remove('Lock')
        const modal = document.querySelector('.DBDeleteWindowModal')
        modal.classList.add('None')
    }

    const openModalCreate = () => {
        document.body.classList.add('Lock')
        const modal = document.querySelector('.DBCreateWindowModal')
        modal.classList.remove('None')
        const windowIsExist = document.querySelector('.WindowIsExist')
        windowIsExist.classList.add('None')
    }

    const closeModalCreate = () => {
        document.body.classList.remove('Lock')
        const modal = document.querySelector('.DBCreateWindowModal')
        modal.classList.add('None')
    }

    const deleteWindowDB = async (id) => {
        closeModalDelete()
        try {
            await deleteWindow(id)
        } catch (e) {

        }
        try {
            await fetchWindowsOneDay(window.selectedDate).then(data => window.setWindow(data))
        } catch (e) {
            window.deleteLast()
            window.setIsEmpty(true)
        }
    }

    const createWindowDB = async (date, time) => {
        const [hour, min] = time.split(':')
        const hh = parseInt(hour)
        const mm = parseInt(min)
        if (!isNaN(hh) && !isNaN(mm)) {
            try {
                await createWindow(date, time)
                closeModalCreate()
                await fetchWindowsOneDay(window.selectedDate).then(data => window.setWindow(data))
            } catch (e) {
                const windowIsExist = document.querySelector('.WindowIsExist')
                windowIsExist.classList.remove('None')
            }
        } else {
            const noTime = document.querySelector('.NoTime')
            noTime.classList.remove('None')
        }
    }

    return (
        <div className="WindowsContainer">
            <div className="WindowsSub">Свободные окна</div>
            {!chooseDate ?
                <div className="WindowsDate" onClick={() => setChooseDate(true)}>Выбрать дату</div>
                :
                <div className="WindowsCalendar">
                    <Calendar />
                    <div className="NoDate None">Дата не выбрана!</div>
                    <div className="OpenWindows" onClick={windowNext}>Далее</div>
                </div>
            }
            <div className="DBWindows None">
                {!window.isEmpty ?
                    <div className="DBWindowsContainer">
                        <div className="DBSelectedDate">{DBSelectedDate(window.selectedDate)}</div>
                        <div className="DBWindowTable">
                            {window.window.map(window => 
                                <div key={window.id} className="DBWindowItem">
                                    <div className="DBWindowItemTime">{DBTime(window.time)}</div>
                                    <div 
                                        className="DBWindowDelete" 
                                        onClick={() => {
                                            openModalDelete()
                                            setDeleteTime(DBTime(window.time))
                                            setDeleteId(window.id)
                                        }}
                                    >
                                        Удалить
                                    </div>
                                    <div className="DBDeleteWindowModal None">
                                        <div className="DBDeleteModalPanel">
                                            <div className="DBDeleteAlert">Удалить окно на <span>{deleteTime}</span>?</div>
                                            <div className="DBDeleteButtons">
                                                <div className="DBDeleteNo" onClick={closeModalDelete}>Отмена</div>
                                                <div 
                                                    className="DBDeleteYes" 
                                                    onClick={() => {
                                                        deleteWindowDB(deleteId)
                                                    }}
                                                >
                                                    Удалить
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <div className="NoWindows">
                        <div className="DBSelectedDate">{DBSelectedDate(window.selectedDate)}</div>
                        <div className="NoWindowsSub">Свободных окон нет</div>
                    </div>
                }
                {window.futureDate ?
                    <div className="AddNewWindow" onClick={openModalCreate}>Создать новое окно</div>
                    :
                    <></>
                }
                <div className="DBCreateWindowModal None">
                    <div className="DBCreateModalPanel">
                        <div className="DBCreateAlert">Создать окно на</div>
                        <input 
                            className="DBCreateInput" 
                            type="time" 
                            onChange={(e) => {
                                setCreateTime(`${e.target.value}:00`)
                                const windowIsExist = document.querySelector('.WindowIsExist')
                                windowIsExist.classList.add('None')
                                const noTime = document.querySelector('.NoTime')
                                noTime.classList.add('None')
                            }} 
                        />
                        <div className="WindowIsExist None">Такое окно уже существует!</div>
                        <div className="NoTime None">Время не выбрано!</div>
                        <div className="DBCreateButtons">
                            <div className="DBCreateNo" onClick={closeModalCreate}>Отмена</div>
                            <div 
                                className="DBCreateYes" 
                                onClick={() => {
                                    createWindowDB(window.selectedDate, createTime)
                                }}
                            >
                                Создать
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})
 
export default Windows;