import React, { useState } from "react";
import './../styles/admin.scss'
import Windows from "../components/Windows";
import Bookings from "../components/Bookings";
import Clients from "../components/Clients";

const Admin = () => {
    const [value, setValue] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [view, setView] = useState(0)

    const checkValue = () => {
        setValue('')
        if (value === process.env.REACT_APP_ADMIN_CODE) {
            setIsAdmin(true)
        } else {
            const adminFalse = document.querySelector('.AdminFalse')
            adminFalse.classList.remove('None')
        }
    }

    return (
        <div>
            <div className="AdminContainer">
                <div className="AdminCheckSub">Панель администратора</div>
                {!isAdmin ?
                    <div className="AdminCheck">
                        <input 
                            className="AdminCheckInput" 
                            type="password" 
                            placeholder="Код доступа"
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value)
                                const adminFalse = document.querySelector('.AdminFalse')
                                adminFalse.classList.add('None')
                            }}
                        />
                        <div className="AdminFalse None">Неверный код доступа!</div>
                        <div className="AdminCheckIn" onClick={checkValue}>Войти</div>
                    </div>
                    :
                    <div className="AdminPanel">
                        {view === 0 ?
                            <div className="PanelButtons">
                                <div className="ViewWindows" onClick={() => setView(1)}>Свободные окна</div>
                                <div className="ViewBookings" onClick={() => setView(2)}>Записи</div>
                                <div className="ViewClients" onClick={() => setView(3)}>Список клиентов</div>
                            </div>
                            : view === 1 ?
                            <div className="Windows">
                                <div className="ViewBack" onClick={() => setView(0)}>Назад</div>
                                <Windows />
                            </div>
                            : view === 2 ?
                            <div className="Bookings">
                                <div className="ViewBack" onClick={() => setView(0)}>Назад</div>
                                <Bookings />
                            </div>
                            :
                            <div className="Clients">
                                <div className="ViewBack" onClick={() => setView(0)}>Назад</div>
                                <Clients />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
 
export default Admin;