import {makeAutoObservable} from 'mobx'

const compareTime = (windowA, windowB) => {
    const timeA = new Date(`1970-01-01T${windowA.time}`)
    const timeB = new Date(`1970-01-01T${windowB.time}`)
    return timeA - timeB
}

export default class BookingStore {
    constructor() {
        this._selectedDate = {}
        this._window = []
        this._isEmpty = true
        this._futureDate = true
        makeAutoObservable(this)
    }

    setSelectedDate(date) {
        this._selectedDate = date
        if (date !== null) {
            const [year, month, day] = date.split('-')
            let thisDate = new Date()
            console.log(year + ' ' + month + ' ' + day)
            if ((year < thisDate.getFullYear()) || (Number(year) === Number(thisDate.getFullYear()) && month < thisDate.getMonth() + 1) || (Number(year) === Number(thisDate.getFullYear()) && Number(month) === Number(thisDate.getMonth()) + 1 && day < thisDate.getDate())) {
                this._futureDate = false
            } else {
                this._futureDate = true
            }
        }
    }

    setWindow(window) {
        this._isEmpty = false
        this._window = window
    }

    setIsEmpty(bool) {
        this._isEmpty = bool
    }

    deleteLast() {
        this._window = undefined
    }

    get selectedDate() {
        return this._selectedDate
    }

    get window() {
        const sortedWindows = this._window.slice().sort((a, b) => compareTime(a, b))
        return sortedWindows
    }

    get isEmpty() {
        return this._isEmpty
    }

    get futureDate() {
        return this._futureDate
    }
}