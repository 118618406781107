import React, { useContext, useState } from "react";
import './../styles/book.scss'
import { observer } from "mobx-react-lite";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { fetchWindowsOneDay } from "../http/windowAPI";
import { createBooking, fetchBookingsOneDay, sendMessageAdmin, sendMessageClient } from "../http/bookingAPI";

const Book = observer(() => {
    const [timeValue, setTimeValue] = useState('12:00')
    const [nameValue, setNameValue] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [typeValue, setTypeValue] = useState('Тип услуги')
    const [createType, setCreateType] = useState('')
    const [createPhone, setCreatePhone] = useState('')

    const {clientBook, window} = useContext(Context)

    const bookingNext =  async(e) => {
        if (clientBook.selectedDate !== null) {
            const bookingsCalendar = document.querySelector('.BookingsCalendar')
            bookingsCalendar.classList.add('None')
            try {
                await fetchWindowsOneDay(clientBook.selectedDate).then(data => window.setWindow(data))
                window.setIsEmpty(false)
            } catch (e) {
                window.setIsEmpty(true)
            }
            const DBBookings = document.querySelector('.ClientBookings')
            DBBookings.classList.remove('None')
        } else {
            const noDate = document.querySelector('.NoDate')
            noDate.classList.remove('None')
        }
    }

    const DBSelectedDate = (date) => {
        try {
            const [year, month, day] = date.split('-')
            const newDate = `${day}.${month}.${year}`
            return newDate
        } catch (e) {

        }
    }

    const DBTime = (time) => {
        const [hour, min] = time.split(':')
        const newTime = `${hour}:${min}`
        return newTime
    }

    const viewBack = () => {
        const bookings = document.querySelector('.ClientBookings')
        bookings.classList.add('None')
        const bookingsCalendar = document.querySelector('.BookingsCalendar')
        bookingsCalendar.classList.remove('None')
        // setChooseDate(false)
        window.setIsEmpty(true)
    }

    const handlePhoneChange = (e) => {
        const input = e.target.value
        const formattedNumber = formatPhoneNumber(input)
        setPhoneNumber(formattedNumber)
    }

    const typeMenu = (e) => {
        const menu = document.querySelector('.DBCreateTypeList')
        menu.classList.toggle('None')
    }

    const formatPhoneNumber = (input) => {
        const cleaned = ('' + input).replace(/\D/g, '')
        setCreatePhone('7'+ cleaned)
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/)
        let formattedNumber
        switch (cleaned.length) {
            case 10:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
                break
            case 9:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}-${match[4]}`
                break
            case 8:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}-`
                break
            case 7:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-${match[3]}`
                break
            case 6:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}-`
                break
            case 5:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}`
                break
            case 4:
                formattedNumber = !match ? '' : `(${match[1]}) ${match[2]}`
                break
            case 3:
                formattedNumber = !match ? '' : `(${match[1]}) `
                break
            case 2:
                formattedNumber = !match ? '' : `(${match[1]}`
                break
            case 1:
                formattedNumber = !match ? '' : `(${match[1]}`
                break
            case 0:
                formattedNumber = !match ? '' : ``
                break

            default:
                break
        }
    
        return formattedNumber;
    }

    const handleBackspace = (e) => {
        if (e.keyCode === 8 || e.key === 'Backspace') {
            e.preventDefault()
            const cleaned = ('' + e.target.value).replace(/\D/g, '')
            const match = cleaned.split('')
            let formattedNumber
            switch (cleaned.length) {
                case 10:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-${match[6]}${match[7]}-${match[8]}`
                    break
                case 9:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-${match[6]}${match[7]}-`
                    break
                case 8:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-${match[6]}`
                    break
                case 7:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}${match[5]}-`
                    break
                case 6:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}${match[4]}`
                    break
                case 5:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) ${match[3]}`
                    break
                case 4:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}${match[2]}) `
                    break
                case 3:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}${match[1]}`
                    break
                case 2:
                    formattedNumber = !match ? '' : 
                    `(${match[0]}`
                    break
                case 1:
                    formattedNumber = !match ? '' : ``
                    break
                case 0:
                    formattedNumber = !match ? '' : ``
                    break

                default:
                    break
            }
            setPhoneNumber(formattedNumber)
        }
    }

    const openModalCreate = () => {
        document.body.classList.add('Lock')
        const modal = document.querySelector('.DBClientBookingModal')
        modal.classList.remove('None')
        setTypeValue('Тип услуги')
        const typeColor = document.querySelector('.DBCreateType')
        typeColor.setAttribute('style', '')
        setNameValue('')
        setPhoneNumber('')
        setCreatePhone('')
        setCreateType('')
        const alerts = document.getElementsByClassName('InputAlert')
        for (let i of alerts) {
            i.classList.add('None')
        }
        const inputName = document.querySelector('.DBCreateInputName')
        const inputPhone = document.querySelector('.DBCreatePhoneContainer')
        const inputType = document.querySelector('.DBCreateType')
        inputName.classList.remove('RedBorder')
        inputPhone.classList.remove('RedBorder')
        inputType.classList.remove('RedBorder')
    }


    const closeModalCreate = async () => {
        document.body.classList.remove('Lock')
        const modal = document.querySelector('.DBClientBookingModal')
        modal.classList.add('None')
        try {
            await fetchWindowsOneDay(clientBook.selectedDate).then(data => window.setWindow(data))
            window.setIsEmpty(false)
        } catch (e) {
            window.setIsEmpty(true)
        }
    }

    const closeFailedBookModal = () => {
        console.log('yes')
        const modal = document.querySelector('.FailedBookModal')
        modal.classList.add('None')
    }

    const closeSuccessedBookModal = () => {
        const modal = document.querySelector('.SuccessedBookModal')
        modal.classList.add('None')
    }

    const createBookingDB = async () => {
        if (clientBook.selectedDate && nameValue.length > 0 && createPhone.length === 11 && createType.length > 0) {
            try {
                console.log(clientBook.selectedTime)
                await createBooking(clientBook.selectedDate, clientBook.selectedTime, nameValue, createPhone, createType)
                await sendMessageAdmin(clientBook.selectedDate, clientBook.selectedTime, nameValue, createPhone, createType)
                await sendMessageClient(clientBook.selectedDate, clientBook.selectedTime, nameValue, createPhone, createType)
                console.log('отправлено')
                closeModalCreate()
                await fetchBookingsOneDay(clientBook.selectedDate).then(data => clientBook.setBooking(data))
                const alert = document.querySelector('.SuccessedBookModal')
                alert.classList.remove('None')
            } catch (e) {
                const alert = document.querySelector('.FailedBookModal')
                alert.classList.remove('None')
                closeModalCreate()
                await fetchWindowsOneDay(clientBook.selectedDate).then(data => window.setWindow(data))
            }
        } else {
            if (nameValue.length === 0) {
                const alert = document.querySelector('.NameAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreateInputName')
                input.classList.add('RedBorder')
            }
            if (createPhone.length !== 11) {
                const alert = document.querySelector('.PhoneAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreatePhoneContainer')
                input.classList.add('RedBorder')
            }
            if (createType.length === 0) {
                const alert = document.querySelector('.TypeAlert')
                alert.classList.remove('None')
                const input = document.querySelector('.DBCreateType')
                input.classList.add('RedBorder')
            }
        }
    }

    return (
        <div>
            <div className="BookContainer">
                <div className="BookBG"></div>
                <div className="BookSub">Запись на услугу</div>
                {/* {!chooseDate ?
                    <div className="BookingsDate" onClick={() => setChooseDate(true)}>Выбрать дату</div>
                    : */}
                    <div className="BookingsCalendar">
                        <Calendar />
                        <div className="NoDate None">Дата не выбрана!</div>
                        <div className="OpenBookings" onClick={bookingNext}>Далее</div>
                    </div>
                {/* } */}
                <div className="FailedBookModal None">
                    <div className="BookedPanel">
                        <div className="BookedAlert">Это время уже занято</div>
                        <div className="BookedClose" onClick={closeFailedBookModal}>Закрыть</div>
                    </div>
                </div>
                <div className="SuccessedBookModal None">
                    <div className="BookedPanel">
                        <div className="BookedAlert">
                            <div className="BookAlertText">Cпасибо, {nameValue}!</div>
                            <div className="BookAlertText">Ожидайте SMS о подтверждении записи</div>
                        </div>
                        <div className="BookedClose" onClick={closeSuccessedBookModal}>Закрыть</div>
                    </div>
                </div>
                <div className="ClientBookings None">
                    <div className="ClientBookBack" onClick={viewBack}>Назад</div>
                    {!window.isEmpty ?
                        <div className="DBWindowsContainer">
                            <div className="DBSelectedDate">{DBSelectedDate(clientBook.selectedDate)}</div>
                            <div className="ChooseWindow">Выберите время</div>
                            <div className="DBWindowTable">
                                {window.window.map(window =>
                                    <div 
                                        key={window.id} 
                                        className="DBWindowItem" 
                                        onClick={(e) => {
                                            setTimeValue(DBTime(window.time))
                                            clientBook.setSelectedTime(window.time)
                                            openModalCreate()
                                        }}
                                    >
                                        <div 
                                            className="DBWindowItemTime" 
                                        >
                                            {DBTime(window.time)}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="DBClientBookingModal None">
                                <div className="DBCreateModalPanel">
                                    <div className="DBCreateDate">{DBSelectedDate(clientBook.selectedDate)}</div>
                                    <div className="DBCreateTime">{timeValue}</div>
                                    <div className="CreateInputs">
                                        <input 
                                            className="DBCreateInputName" 
                                            type="text" 
                                            placeholder="Имя" 
                                            value={nameValue}
                                            onChange={(e) => {
                                                setNameValue(`${e.target.value}`)
                                                e.target.classList.remove('RedBorder')
                                                const alert = document.querySelector('.NameAlert')
                                                alert.classList.add('None')
                                            }}
                                        />
                                        <div className="InputAlert NameAlert None">Введите имя</div>
                                        <div className="DBCreatePhoneContainer">
                                            <div className="DBCreatePhonePre">+7</div>
                                            <input 
                                                className="DBCreateInputPhone"
                                                type="text" 
                                                maxLength="15" 
                                                value={phoneNumber}
                                                onChange={(e) => {
                                                    handlePhoneChange(e)
                                                    const input = document.querySelector('.DBCreatePhoneContainer')
                                                    input.classList.remove('RedBorder')
                                                    const alert = document.querySelector('.PhoneAlert')
                                                    alert.classList.add('None')
                                                }}
                                                onKeyDown={handleBackspace}
                                                placeholder="(999) 999-99-99"
                                            />
                                        </div>
                                        <div className="InputAlert PhoneAlert None">Некорректный номер телефона</div>
                                        <div className="DBCreateType" onClick={typeMenu}>
                                            {typeValue} <span>▴</span>
                                            <div className="DBCreateTypeList None">
                                                <div 
                                                    className="DBCreateTypeItem" 
                                                    id="MOMENT" 
                                                    onClick={(e) => {
                                                        setTypeValue(`${e.target.innerText}`)
                                                        setCreateType(`${e.target.id}`)
                                                        const color = document.querySelector('.DBCreateType')
                                                        color.setAttribute('style', 'color: black')
                                                        const input = document.querySelector('.DBCreateType')
                                                        input.classList.remove('RedBorder')
                                                        const alert = document.querySelector('.TypeAlert')
                                                        alert.classList.add('None')
                                                    }}
                                                >
                                                    Моментальный загар
                                                </div>
                                                <div 
                                                    className="DBCreateTypeItem" 
                                                    id="EXPRESS"
                                                    onClick={(e) => {
                                                        setTypeValue(`${e.target.innerText}`)
                                                        setCreateType(`${e.target.id}`)
                                                        const color = document.querySelector('.DBCreateType')
                                                        color.setAttribute('style', 'color: black')
                                                        const input = document.querySelector('.DBCreateType')
                                                        input.classList.remove('RedBorder')
                                                    }}
                                                >
                                                    Экспресс загар
                                                </div>
                                                <div 
                                                    className="DBCreateTypeItem" 
                                                    id="PREMIUM"
                                                    onClick={(e) => {
                                                        setTypeValue(`${e.target.innerText}`)
                                                        setCreateType(`${e.target.id}`)
                                                        const color = document.querySelector('.DBCreateType')
                                                        color.setAttribute('style', 'color: black')
                                                        const input = document.querySelector('.DBCreateType')
                                                        input.classList.remove('RedBorder')
                                                    }}
                                                >
                                                    Премиум загар
                                                </div>
                                            </div>
                                        </div>
                                        <div className="InputAlert TypeAlert None">Выберите тип услуги</div>
                                    </div>
                                    <div className="DBCreateButtons">
                                        <div className="DBCreateNo" onClick={closeModalCreate}>Отмена</div>
                                        <div 
                                            className="DBCreateYes" 
                                            onClick={() => {
                                                createBookingDB()
                                            }}
                                        >
                                            Записаться
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="NoWindows">
                            <div className="DBSelectedDate">{DBSelectedDate(clientBook.selectedDate)}</div>
                            <div className="NoWindowsSub">Свободных окон нет</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
})
 
export default Book;