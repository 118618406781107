import React, { useState } from "react";
import './../styles/services.scss'
import { useNavigate } from "react-router-dom";

import tan1 from './../img/greyTan1.jpg'
import tan2 from './../img/greyTan2.jpg'
import tan3 from './../img/greyTan3.jpg'
import Loading from "./Loading";

const Services = () => {
    const navigate = useNavigate()
    const [loaded, setLoaded] = useState(false)

    const handleNavigate = (e) => {
        window.scrollTo(0, 0)
        navigate(`${e.target.id}`)
    }

    const load = () => {
        setLoaded(true)
    }

    return (
        <div>
            {!loaded ?
                <Loading />
                :
                <></>
            }
            <div className={`ServicesContainer ${loaded ? 'Loaded' : ''}`} onLoad={load}>
                <div className="MomentType">
                    <div className="TypeImg"><img src={tan1} alt="tan" /></div>
                    <div className="TypeDesc">
                        <div className="TDSub">Моментальный загар</div>
                        <div className="TDList">
                            <div className="TDItem">– Консультация и подбор тона</div>
                            <div className="TDItem">– Выдержка до первого душа 8 часов</div>
                            <div className="TDItem">– Проявляется в течение суток</div>
                            <div className="TDPrice">1800&nbsp;</div>
                        </div>
                        <div className="TDBook" id="/booking" onClick={handleNavigate}>Записаться</div>
                    </div>
                </div>
                <div className="ExpressType">
                    <div className="TypeDesc">
                        <div className="TDSub">Экспресс загар</div>
                        <div className="TDList">
                            <div className="TDItem">– Консультация и подбор тона</div>
                            <div className="TDItem">– Выдержка до первого душа 3-5 часов</div>
                            <div className="TDItem">– Проявляется в течение суток</div>
                            <div className="TDPrice">2200&nbsp;</div>
                        </div>
                        <div className="TDBook" id="/booking" onClick={handleNavigate}>Записаться</div>
                    </div>
                    <div className="TypeImg"><img src={tan2} alt="tan" /></div>
                </div>
                <div className="PremiumType">
                    <div className="TypeImg"><img src={tan3} alt="tan" /></div>
                    <div className="TypeDesc">
                        <div className="TDSub">Премиум загар</div>
                        <div className="TDList">
                            <div className="TDItem">– Консультация и подбор тона</div>
                            <div className="TDItem">– Используется лосьон без липкости</div>
                            <div className="TDItem">– Выдержка до первого душа 3-5 часов</div>
                            <div className="TDItem">– Проявляется в течение 6 часов</div>
                            <div className="TDPrice">2200&nbsp;</div>
                        </div>
                        <div className="TDBook" id="/booking" onClick={handleNavigate}>Записаться</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Services;