import { $host, $smsHost } from ".";

const formatDate = (date) => {
    const [year, month, day] = date.split('-')
    let monthText = ''
    switch (month) {
        case '01':
            monthText = 'января'
            break;
        case '02':
            monthText = 'февраля'
            break
        case '03':
            monthText = 'марта'
            break
        case '04':
            monthText = 'апреля'
            break
        case '05':
            monthText = 'мая'
            break
        case '06':
            monthText = 'июня'
            break
        case '07':
            monthText = 'июля'
            break
        case '08':
            monthText = 'августа'
            break
        case '09':
            monthText = 'сентября'
            break
        case '10':
            monthText = 'октября'
            break
        case '11':
            monthText = 'ноября'
            break
        case '12':
            monthText = 'декабря'
            break
        default:
            break
    }
    const newDate = `${day} ${monthText} ${year}`
    return newDate
}

const formatTime = (time) => {
    const [hh, mm] = time.split(':')
    const newTime = `${hh}:${mm}`
    return newTime
}

const formatType = (type) => {
    let newType = ''
    switch (type) {
        case 'MOMENT':
            newType = 'Моментальный загар'
            break
        case 'EXPRESS':
            newType = 'Экспресс загар'
            break
        case 'PREMIUM':
            newType = 'Премиум загар'
            break
        default:
            break;
    }
    return newType
}

const smallFormatType = (type) => {
    let newType = ''
    switch (type) {
        case 'MOMENT':
            newType = 'моментальный загар'
            break
        case 'EXPRESS':
            newType = 'экспресс загар'
            break
        case 'PREMIUM':
            newType = 'премиум загар'
            break
        default:
            break;
    }
    return newType
}

export const createBooking = async (date, time, name, phone, type) => {
    const {data} = await $host.post('api/booking/admin', {date, time, name, phone, type})
    return data
}

export const fetchBookingsOneDay = async (date) => {
    const {data} = await $host.get('api/booking/day', {params: {date}})
    return data
}

export const deleteBooking = async (id) => {
    await $host.delete('api/booking', {params: {id}})
}

export const sendMessageAdmin = async (date, time, name, phone, type) => {
    const number = '79809589388'
    const destination = '79172682101'
    const text = `✅НОВАЯ ЗАПИСЬ\n${formatDate(date)}\n${formatTime(time)}\n${formatType(type)}\n${name}\n${phone}`
    const token = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJRV05sMENiTXY1SHZSV29CVUpkWjVNQURXSFVDS0NWODRlNGMzbEQtVHA0In0.eyJleHAiOjIwMDI4MjQwNzksImlhdCI6MTY4NzQ2NDA3OSwianRpIjoiMGI4OTcwOWItZWUxZC00M2RiLWE1NTctYjg3OWNkMjhiODNjIiwiaXNzIjoiaHR0cHM6Ly9zc28uZXhvbHZlLnJ1L3JlYWxtcy9FeG9sdmUiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiODA2NzRhNTEtNTM1NS00NTIwLTkzYjYtYWQwMzhkYjhiZmZkIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiNDM2MTgyYjQtODgyZi00ZDA5LWFlMDYtMjBiM2YwYTRlZGE3Iiwic2Vzc2lvbl9zdGF0ZSI6IjY4YTEzMTNlLTc1ZjMtNGY2Zi04MmU2LTUzYjNmZTgzNDkzNyIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1leG9sdmUiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJleG9sdmVfYXBwIHByb2ZpbGUgZW1haWwiLCJzaWQiOiI2OGExMzEzZS03NWYzLTRmNmYtODJlNi01M2IzZmU4MzQ5MzciLCJ1c2VyX3V1aWQiOiI3ZDJlMjI3NC1mYTUxLTQwNGYtYjUxMi1iMjBkMWQ0NjgzZDAiLCJjbGllbnRJZCI6IjQzNjE4MmI0LTg4MmYtNGQwOS1hZTA2LTIwYjNmMGE0ZWRhNyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY2xpZW50SG9zdCI6IjE3Mi4yMC4yLjIxIiwiYXBpX2tleSI6dHJ1ZSwiYXBpZm9uaWNhX3NpZCI6IjQzNjE4MmI0LTg4MmYtNGQwOS1hZTA2LTIwYjNmMGE0ZWRhNyIsImJpbGxpbmdfbnVtYmVyIjoiMTE5NzgzMSIsImFwaWZvbmljYV90b2tlbiI6ImF1dGEwYTQ3NjI2LTRmOTctNGExOS1hYjgyLThmODVlZGMyMGUwNyIsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC00MzYxODJiNC04ODJmLTRkMDktYWUwNi0yMGIzZjBhNGVkYTciLCJjdXN0b21lcl9pZCI6IjI2NTIxIiwiY2xpZW50QWRkcmVzcyI6IjE3Mi4yMC4yLjIxIn0.eK43fU_EfMLCaooFi7iFVxKcuBRkFvYyO5ykGArHtnCHvGlsIaKia9QLH6eT9YT3LAXV-UUm2dr9IsG0v_lMRssRGxTWg1VqbQxJI6Jz8W2WEF8PQaccLNdV6YSyQo0YveBD6FMmZLkBNLutwpjfHnQdLJRSMYZ3FNK5Hj1GsUrUcsuUGEGzeH7ET-FPSwFqoPeOjk1CdoqzKix7CUL4OUT1zRAqcOU8r2lgAg4S0bhzwsGe13RpvdGLJHy0mE8yYuC7uFy9R_G2NOOeBruIy3ubTVZS51Lx_zqQNfocZaJ58Ppa6iyulJn9Jg60miU2Xla4elLGtwbzcr1X3UmK3Q'
    try {
        const {data} = await $smsHost.post('', {number, destination, text}, {headers: {Authorization: token}})
        console.log('SMS sent successfully:', data)
    } catch (error) {
        console.error('Failed to send SMS:', error.response.data)
    }
}

export const sendMessageClient = async (date, time, name, phone, type) => {
    const number = '79809589388'
    const destination = `${phone}`
    const text = `Здравствуйте, ${name}!\n\nВы записаны на ${smallFormatType(type)} на ${formatDate(date)} в ${formatTime(time)}.\n\nЖдём вас по адресу:\nул. Рината Галеева, 3, ТЦ Алсу, павильон 219\n\nТелефон: +79539992422\n\nC уважением,\nстудия загара Pudra🤍`
    const token = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJRV05sMENiTXY1SHZSV29CVUpkWjVNQURXSFVDS0NWODRlNGMzbEQtVHA0In0.eyJleHAiOjIwMDI4MjQwNzksImlhdCI6MTY4NzQ2NDA3OSwianRpIjoiMGI4OTcwOWItZWUxZC00M2RiLWE1NTctYjg3OWNkMjhiODNjIiwiaXNzIjoiaHR0cHM6Ly9zc28uZXhvbHZlLnJ1L3JlYWxtcy9FeG9sdmUiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiODA2NzRhNTEtNTM1NS00NTIwLTkzYjYtYWQwMzhkYjhiZmZkIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiNDM2MTgyYjQtODgyZi00ZDA5LWFlMDYtMjBiM2YwYTRlZGE3Iiwic2Vzc2lvbl9zdGF0ZSI6IjY4YTEzMTNlLTc1ZjMtNGY2Zi04MmU2LTUzYjNmZTgzNDkzNyIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1leG9sdmUiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJleG9sdmVfYXBwIHByb2ZpbGUgZW1haWwiLCJzaWQiOiI2OGExMzEzZS03NWYzLTRmNmYtODJlNi01M2IzZmU4MzQ5MzciLCJ1c2VyX3V1aWQiOiI3ZDJlMjI3NC1mYTUxLTQwNGYtYjUxMi1iMjBkMWQ0NjgzZDAiLCJjbGllbnRJZCI6IjQzNjE4MmI0LTg4MmYtNGQwOS1hZTA2LTIwYjNmMGE0ZWRhNyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY2xpZW50SG9zdCI6IjE3Mi4yMC4yLjIxIiwiYXBpX2tleSI6dHJ1ZSwiYXBpZm9uaWNhX3NpZCI6IjQzNjE4MmI0LTg4MmYtNGQwOS1hZTA2LTIwYjNmMGE0ZWRhNyIsImJpbGxpbmdfbnVtYmVyIjoiMTE5NzgzMSIsImFwaWZvbmljYV90b2tlbiI6ImF1dGEwYTQ3NjI2LTRmOTctNGExOS1hYjgyLThmODVlZGMyMGUwNyIsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC00MzYxODJiNC04ODJmLTRkMDktYWUwNi0yMGIzZjBhNGVkYTciLCJjdXN0b21lcl9pZCI6IjI2NTIxIiwiY2xpZW50QWRkcmVzcyI6IjE3Mi4yMC4yLjIxIn0.eK43fU_EfMLCaooFi7iFVxKcuBRkFvYyO5ykGArHtnCHvGlsIaKia9QLH6eT9YT3LAXV-UUm2dr9IsG0v_lMRssRGxTWg1VqbQxJI6Jz8W2WEF8PQaccLNdV6YSyQo0YveBD6FMmZLkBNLutwpjfHnQdLJRSMYZ3FNK5Hj1GsUrUcsuUGEGzeH7ET-FPSwFqoPeOjk1CdoqzKix7CUL4OUT1zRAqcOU8r2lgAg4S0bhzwsGe13RpvdGLJHy0mE8yYuC7uFy9R_G2NOOeBruIy3ubTVZS51Lx_zqQNfocZaJ58Ppa6iyulJn9Jg60miU2Xla4elLGtwbzcr1X3UmK3Q'
    try {
        const {data} = await $smsHost.post('', {number, destination, text}, {headers: {Authorization: token}})
        console.log('SMS sent successfully:', data)
    } catch (error) {
        console.error('Failed to send SMS:', error.response.data)
    }
}