import axios from 'axios'

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $smsHost = axios.create({
    baseURL: process.env.REACT_APP_API_SMSURL
})

export {$host, $smsHost}