import React, { createContext } from 'react';
import { BrowserRouter as Router} from 'react-router-dom'
import ReactDOM from 'react-dom/client';
import App from './App';
import WindowStore from './store/WindowStore'
import BookingStore from './store/BookingStore';
import ClientStore from './store/ClientStore';
import ClientBookStore from './store/ClientBookStore';

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Context.Provider value={{
      window: new WindowStore(),
      booking: new BookingStore(),
      client: new ClientStore(),
      clientBook: new ClientBookStore()
    }}>
      <App />
    </Context.Provider>
  </Router>
);