import React, { useEffect, useState } from "react";
import AppRoutes from "./AppRoutes";

import './styles/base.scss'
import './styles/app.scss'
import { useNavigate } from "react-router-dom";

import vk from './img/icons/vk1.png'
import inst from './img/icons/inst1.png'
import whatsapp from './img/icons/whatsapp1.png'
import telegram from './img/icons/telegram1.png'

const App = () => {
    const navigate = useNavigate()
    const [date, setDate] = useState('')
    const [scrollPos, setScrollPos] = useState(0)

    const handleNavigate = (e) => {
        window.scrollTo(0, 0)
        navigate(`${e.target.id}`)
        setScrollPos(0)
        const burgerBtn = document.querySelector('.HeaderBurger')
        if (burgerBtn.classList.contains('ActiveBurger'))
        burger()
    }

    const burger = (e) => {
        const burgerBtn = document.querySelector('.HeaderBurger')
        const scrollPosition = window.pageYOffset
        if (!burgerBtn.classList.contains('ActiveBurger')) {
            setScrollPos(scrollPosition);
        }
        burgerBtn.classList.toggle('ActiveBurger')
        const menu = document.querySelector('.Menu')
        menu.classList.toggle('ActiveMenu')
        const content = document.querySelector('.Content')
        content.classList.toggle('ActiveContent')
        const footer = document.querySelector('.FooterContent')
        footer.classList.toggle('ActiveContent')
        const body = document.querySelector('.UnderMenu')
        body.classList.toggle('Lock')
        body.setAttribute('style', `top: -${scrollPosition}px`)
        console.log(scrollPos)
        if (!burgerBtn.classList.contains('ActiveBurger')) {
            window.scrollTo(0, scrollPos)
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behaviour: 'smooth'
        })
        const nowDate = new Date()
        const startDate = document.querySelector('.StartDate')
        if (startDate.innerText < nowDate.getFullYear())
        setDate(' — ' + nowDate.getFullYear())
    }, [])

    return (
        <div className="App">
            <header className="Header">
                <div className="HeaderContent">
                    <div className="HeaderNavigation">
                        <div className="HeaderPudra" id="/" onClick={handleNavigate}>Pudra</div>
                        <div className="HeaderNav">
                            <div className="NavItem" id="/services" onClick={handleNavigate}>Услуги</div>
                            <div className="NavItem" id="/sales" onClick={handleNavigate}>Акции</div>
                            <div className="NavItem" id="/tips" onClick={handleNavigate}>Советы</div>
                            <div className="NavItem" id="/aboutus" onClick={handleNavigate}>О нас</div>
                        </div>
                    </div>
                    <div className="NavBook" id="/booking" onClick={handleNavigate}>Записаться</div>
                    <div className="HeaderBurger" onClick={burger}><span></span></div>
                </div>
            </header>
            <menu className="Menu">
                <ul className="MenuItems">
                    <li className="MenuItem" id="/" onClick={handleNavigate}><span id="/">Главная</span></li>
                    <li className="MenuItem" id="/services" onClick={handleNavigate}><span id="/services">Услуги</span></li>
                    <li className="MenuItem" id="/sales" onClick={handleNavigate}><span id="/sales">Акции и скидки</span></li>
                    <li className="MenuItem" id="/tips" onClick={handleNavigate}><span id="/tips">Рекомендации</span></li>
                    <li className="MenuItem" id="/aboutus" onClick={handleNavigate}><span id="/aboutus">О нас</span></li>
                    <li className="MenuItem PinkItem" id="/booking" onClick={handleNavigate}>Записаться</li>
                </ul>
            </menu>
            <div className="UnderMenu">
                {/* <div className="Content" onClick={quit}>
                    <AppRoutes />
                </div> */}
                <div className="Content">
                    <AppRoutes />
                </div>
                {/* <footer className="Footer" onClick={quit}> */}
                <footer className="Footer">
                    <div className="FooterContent">
                        <div className="FooterTop">
                            <div className="FirstWrap">
                                <div className="LogoContainerFooter">
                                    <div className="LogoFooter" id="/" onClick={handleNavigate}>Pudra</div>
                                    <div className="PudraDescription">
                                        Мы — профессиональная студия моментального загара, где каждый клиент может 
                                        получить красивый и равномерный загар всего за несколько минут. Обращайтесь 
                                        к нам и наслаждайтесь красивым загаром круглый год!
                                    </div>
                                </div>
                                <div className="FooterLine"></div>
                                <div className="LinksContainerFooter">
                                    <div className="LinkFooter" id="/services" onClick={handleNavigate}>Услуги</div>
                                    <div className="LinkFooter" id="/sales" onClick={handleNavigate}>Акции и скидки</div>
                                    <div className="LinkFooter" id="/tips" onClick={handleNavigate}>Рекомендации</div>
                                    <div className="LinkFooter" id="/aboutus" onClick={handleNavigate}>О нас</div>
                                </div>
                                <div className="FooterLine"></div>
                                <div className="MediaContainerFooter">
                                    <div className="MediaSubFooter">Следите за свежими новостями:</div>
                                    <div className="MediaLinks">
                                        <div className="MediaLink VKLink"><a href="https://vk.com/pudrazagar" target="_blank" rel="noreferrer"><img src={vk} alt="" /></a></div>
                                        <div className="MediaLink"><a href="https://instagram.com/pudrazagar?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer"><img src={inst} alt="" /></a></div>
                                        <div className="MediaLink"><a href="https://wa.me/79539992422" target="_blank" rel="noreferrer"><img src={whatsapp} alt="" /></a></div>
                                        <div className="MediaLink"><a href="https://t.me/julyvlady83" target="_blank" rel="noreferrer"><img src={telegram} alt="" /></a></div>
                                    </div>
                                </div>
                                <div className="FooterLine"></div>
                            </div>
                            <div className="SecondWrap">
                                <div className="AddressContainerFooter">
                                    <div className="AddressFooter">г. Альметьевск, ул. Рината Галеева,&nbsp;<span>3</span>, ТЦ Алсу, павильон&nbsp;<span>219</span></div>
                                    <div className="AddressFooter">Моментальный загар и солярий</div>
                                    <div className="AddressFooter"><a className="PhoneFooter" href="tel: +79539992422">+7 (953) 999-24-22</a></div>
                                    <a className="PudraMap" href="https://yandex.ru/maps/?um=constructor%3A5fc87517a3804ec8b297ca6d0bfa5334b1bb90df8da9143094bb44f473c1a829&amp;source=constructorStatic" target="_blank" rel="noreferrer"><img src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A5fc87517a3804ec8b297ca6d0bfa5334b1bb90df8da9143094bb44f473c1a829&amp;width=600&amp;height=400&amp;lang=ru_RU" alt="" /></a>
                                </div>
                                <div className="FooterLine2"></div>
                                <div className="AddressContainerFooter">
                                    <div className="AddressFooter">г. Альметьевск, ул. Мира,&nbsp;<span>2</span>, этаж&nbsp;<span>2</span>, салон красоты Elli Belli</div>
                                    <div className="AddressFooter">Солярий</div>
                                    <div className="AddressFooter"><a className="PhoneFooter" href="tel: +79674660444" target="_blank" rel="noreferrer">+7 (967) 466-04-44</a></div>
                                    <a className="PudraMap" href="https://yandex.ru/maps/?um=constructor%3Ab248d218ad28c7e69ede198cebc37aa7f2ad305cb5828df2c8fa189f04048147&amp;source=constructorStatic" target="_blank" rel="noreferrer"><img src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3Ab248d218ad28c7e69ede198cebc37aa7f2ad305cb5828df2c8fa189f04048147&amp;width=600&amp;height=400&amp;lang=ru_RU" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="FooterBottom">
                            <div className="PudraRules">© Все права защищены&nbsp;<span className="StartDate">2023{date}</span>, Pudra</div>
                            <div className="Developer">Сайт разработан&nbsp;<div className="DevLink"><a href="https://anastasiabatyrova.ru" target="_blank" rel="noreferrer">anastasiabatyrova.ru</a></div></div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}
 
export default App;