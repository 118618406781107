import React from "react";

import './../styles/aboutUs.scss'

import rev1 from './../img/Rev1.jpg'
import rev2 from './../img/Rev2.jpg'
import rev3 from './../img/Rev3.jpg'
import rev4 from './../img/Rev4.jpg'
import rev5 from './../img/Rev5.jpg'
import arrow from './../img/icons/arrow.png'
import arrow2 from './../img/icons/arrow2.png'

const AboutUs = () => {
    return (
        <div>
            <div className="AboutUsContainer">
                <div className="BackLinks">
                    <a href="../index.html">Главная</a>
                    <span>/</span>
                    <span className="ActiveLink">О нас</span>
                </div>
                <div className="AboutSub">Рады приветствовать вас на сайте Pudra!</div>
                <div className="AboutRow">
                    <div className="AboutUsText">
                        <div className="AboutPar">
                            Нашей главной миссией является не только доставить радость людям через прекрасный загар,
                            но и сделать это безопасно и профессионально. Начиная с 2017 года, мы уделяем особое
                            внимание развитию и совершенствованию наших услуг.
                        </div>
                        <div className="AboutPar">
                            Мы стремимся к тому, чтобы ваш опыт в нашем салоне был комфортным и информативным.
                            Перед началом процедур мы проводим консультацию, где детально объясняем процесс и даем рекомендации
                            для получения и поддержания желаемого результата. Наша команда всегда готова ответить на ваши
                            вопросы и предоставить профессиональный совет.
                        </div>
                        <div className="AboutPar">
                            Мы предлагаем не только услуги загара, но и продукты, специально разработанные для ухода за
                            кожей после процедуры, включая увлажняющие кремы, бальзамы и средства с SPF, чтобы ваша кожа
                            всегда оставалась здоровой и сияющей.
                        </div>
                        <div className="AboutPar">
                            Доверьтесь нашему опыту и заботе о вашей коже, и мы поможем вам достичь
                            желаемого загара и сохранить его на долгое время.
                        </div>
                    </div>
                    <div className="AboutNumbers">
                        <div className="NumbersInfo">
                            <div className="AboutNum">
                                <div className="NumSub">6 лет</div>
                                <div className="NumText">усердного труда</div>
                            </div>
                            <div className="AboutNum">
                                <div className="NumSub">более 1000</div>
                                <div className="NumText">счастливых клиентов</div>
                            </div>
                        </div>
                        <div className="Contacts">
                            <div className="ContactName">Юлия</div>
                            <div className="ContactPhone"><a href="tel:+79539992422">+7 (953) 999-24-22</a></div>
                            <div className="ContactEmail"><a href="mailto:pudrazagar@gmail.com">pudrazagar@gmail.com</a></div>
                        </div>
                    </div>
                </div>
                <div className="MapSub">Мы на карте</div>
                <div className="MapContainer">
                    <iframe className="AboutUsMap" title="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A3ee9f93d5765cb6ec84ef14ad52247d776428627ed1067f4f9110b6ccd8322ae&amp;source=constructor"></iframe>
                </div>
                <div className="ReviewsSub">Отзывы</div>
                <div className="ReviewsBox">
                    <div className="ReviewsContainer">
                        <div className="ReviewsRow">
                            <div className="Review">
                                <div className="ReviewInfo">
                                    <div className="ReviewName">Разиля</div>
                                    <div className="ReviewText">
                                        <p>Юлия, добрый день🌸</p>
                                        <p>Лосьон продержала 8 часов, прям шоколадка🤭 Смылось красиво, ровно, спасибо большое Вам❤️</p>
                                    </div>
                                </div>
                                <button className="ReviewImgBtn" id="1btn">
                                    <img className="ReviewImg" id="1img" src={rev1} alt="Review" />
                                </button>
                            </div>
                            <div className="Review">
                                <div className="ReviewInfo">
                                    <div className="ReviewName">Екатерина</div>
                                    <div className="ReviewText">
                                        <p>Юленька, добрый день🤍</p>
                                        <p>Спасибо большое за загар, лег идеально🙏🏼</p>
                                    </div>
                                </div>
                                <button className="ReviewImgBtn" id="2btn">
                                    <img className="ReviewImg" id="2img" src={rev2} alt="Review" />
                                </button>
                            </div>
                            <div className="Review">
                                <div className="ReviewInfo">
                                    <div className="ReviewName">Светлана</div>
                                    <div className="ReviewText">
                                        <p>Здравствуйте!</p>
                                        <p>С белым платьем было шикарно🥰</p>
                                    </div>
                                </div>
                                <div className="RevImgRow">
                                    <button className="ReviewImgBtn" id="3btn">
                                        <img className="ReviewImg" id="3img" src={rev3} alt="Review" />
                                    </button>
                                    <button className="ReviewImgBtn SecondImg" id="3btnSecond">
                                        <img className="ReviewImg SecondImg" id="3imgSecond" src={rev4} alt="Review" />
                                    </button>
                                </div>
                            </div>
                            <div className="Review">
                                <div className="ReviewInfo">
                                    <div className="ReviewName">Виктория</div>
                                    <div className="ReviewText">
                                        <p>Здравствуйте!) Ваш загар😍 Очень красиво!</p>
                                        <p>Хочу ещё... И всегда быть такой😅</p>
                                    </div>
                                </div>
                                <button className="ReviewImgBtn" id="5btn">
                                    <img className="ReviewImg" id="5img" src={rev5} alt="Review" />
                                </button>
                            </div>
                        </div>
                        <div className="ReviewsBtns">
                            <button className="ReviewLeft Left1"><img src={arrow} alt="Arrow" /></button>
                            <button className="ReviewRight Right1 ActiveRevBtn"><img src={arrow} alt="Arrow" /></button>
                            <button className="ReviewLeft Left2"><img src={arrow} alt="arrow" /></button>
                            <button className="ReviewRight Right2 ActiveRevBtn"><img src={arrow} alt="Arrow" /></button>
                        </div>
                    </div>
                    <div className="ImgModalContainer None" id="Rev1">
                        <div className="Images">
                            <div className="ImgContainer None" id="Img1">
                                <button className="CloseModal" id="1btnCls">
                                    <img src="../static/img/icons/close.png" alt="Close" />
                                </button>
                                <img className="ImgModal" src={rev1} alt="Review" />
                            </div>
                        </div>
                    </div>
                    <div className="ImgModalContainer None" id="Rev2">
                        <div className="ImgContainer None" id="Img2">
                            <button className="CloseModal" id="2btnClose">
                                <img src="../static/img/icons/close.png" alt="Close" />
                            </button>
                            <img className="ImgModal" src={rev2} alt="Review" />
                        </div>
                    </div>
                    <div className="ImgModalContainer None" id="Rev3">
                        <div className="Images">
                            <div className="ImgContainer None" id="Img3">
                                <button className="CloseModal" id="3btnClose">
                                    <img src="../static/img/icons/close.png" alt="Close" />
                                </button>
                                <img className="ImgModal" src={rev3} alt="Review" />
                            </div>
                            <div className="ImgContainer None SecondImg3">
                                <button className="CloseModal" id="3btnCloseSecond">
                                    <img src="../static/img/icons/close.png" alt="Close" />
                                </button>
                                <img className="ImgModal" src={rev4} alt="Review" />
                            </div>
                            <div className="ImgBtns">
                                <button className="ImgLeft"><img src={arrow2} alt="Arrow" /></button>
                                <button className="ImgRight ActiveImgBtn"><img src={arrow2} alt="Arrow" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="ImgModalContainer None" id="Rev5">
                        <div className="ImgContainer None" id="Img5">
                            <button className="CloseModal" id="5btnClose">
                                <img src="../static/img/icons/close.png" alt="Close" />
                            </button>
                            <img className="ImgModal" src={rev5} alt="Review" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;