import { makeAutoObservable } from "mobx"

export default class ClientBookStore {
    constructor() {
        this._selectedDate = {}
        this._client = []
        this._booking = []
        this._windows = []
        this._selectedTime = ''
        makeAutoObservable(this)
    }

    setSelectedDate(date) {
        this._selectedDate = date
    }

    setClient(client) {
        this._client = client
    }

    setBooking(booking) {
        this._booking = booking
    }

    setWindows(windows) {
        this._windows = windows
    }

    setSelectedTime(time) {
        this._selectedTime = time
    }

    get selectedDate() {
        return this._selectedDate
    }

    get client() {
        return this._client
    }

    get booking() {
        return this._booking
    }

    get windows() {
        return this._windows
    }

    get selectedTime() {
        return this._selectedTime
    }
}