import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Services from "./pages/Services";
import Sales from "./pages/Sales";
import Tips from "./pages/Tips";
import AboutUs from "./pages/AboutUs";
import Admin from "./pages/Admin";
import Book from "./pages/Book";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element = { <Main /> } />
            <Route path="/services" element = { <Services /> } />
            <Route path="/sales" element = { <Sales /> } />
            <Route path="/tips" element = { <Tips /> } />
            <Route path="/aboutus" element = { <AboutUs /> } />
            <Route path="/admin" element = { <Admin /> } />
            <Route path="/booking" element = { <Book /> } />
        </Routes>
    );
}
 
export default AppRoutes;