import React, { useState } from "react";
import './../styles/sales.scss'

const Sales = () => {
    const [loaded, setLoaded] = useState(true)

    const load = () => {
        setLoaded(true)
    }

    return (
        <div>
            <div className={`SalesContainer ${loaded ? 'Loaded' : ''}`} onLoad={load}>
                <div className="SalesSub">Акции и скидки</div>
            </div>
        </div>
    );
}
 
export default Sales;