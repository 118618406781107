import { $host } from ".";

export const fetchClients = async (offset, limit) => {
    const {data} = await $host.get('api/client/limit', {params: {offset, limit}})
    return data
}

export const searchClients = async (search) => {
    const {data} = await $host.get('api/client/search', {params: {search}})
    return data
}

export const searchBookings = async (client_id) => {
    const {data} = await $host.get('api/client/bookings', {params: {client_id}})
    return data
}